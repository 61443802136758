<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogActivateInactivateUser" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div><span v-text="StoreObj.user_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span> User</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogActivateInactivateUserEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" :color="'red'" v-text="'mdi-delete'"></v-icon>
          <div>Are you sure you want to delete {{ StoreObj.user_email_id }} ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogActivateInactivateUserEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="activateInactivateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogActivateInactivateUser: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
    },
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async activateInactivateMethod() {
      this.loading = true;
      var self = this;
      const path = "deleteuser";
      const myInit = {
        body: {
          user_email_id: this.StoreObj.user_email_id,
          updater_email_id: this.$store.getters.get_user_email,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            self.dialogActivateInactivateUserEmit((self.Toggle = 2));
            self.loading = false;
          } else {
            self.loading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
