<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateBMSUser">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div v-text="StoreObj.action == 'CREATE' ? 'Create User' : 'Edit User'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createUserDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-text-field dense outlined label="Name" class="field_height field_label_size FontSize" :rules="[(v) => !!v || 'Required']" v-model="create_user.user_name"></v-text-field>
            <v-text-field dense outlined label="Email ID" class="field_height field_label_size FontSize" :rules="[(v) => !!v || 'Required']" v-model="create_user.user_email_id"></v-text-field>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="5">
                <v-select
                  dense
                  outlined
                  label="Country Code"
                  class="field_height field_label_size FontSize mr-2"
                  item-text="dial_code"
                  item-value="dial_code"
                  :items="CountryList"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_user.user_country_code"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="7">
                <v-text-field
                  dense
                  outlined
                  label="Mobile Number"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_user.user_mobile_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-select
              dense
              outlined
              label="User Type"
              class="field_height field_label_size FontSize"
              item-text="text"
              item-value="value"
              :items="userTypeItems"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_user.user_type"
            ></v-select>
            <v-select
              dense
              outlined
              label="Reporting Manager"
              class="field_height field_label_size FontSize"
              item-text="user_name"
              item-value="user_id"
              :items="GetAllUsersList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_user.reporting_manager_id"
            ></v-select>
            <v-select
              dense
              outlined
              label="Role"
              class="field_height field_label_size FontSize"
              item-text="role_name"
              item-value="role_id"
              :items="GetAllRolesList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_user.role_id"
            ></v-select>
            <v-select
              dense
              outlined
              label="Department"
              class="field_height field_label_size FontSize"
              item-text="department_name"
              item-value="department_id"
              :items="GetAllDepartmentsList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_user.department_id"
            ></v-select>
            <v-checkbox dense class="pa-0 ma-0" label="Is Expert Comment Enabled" v-model="create_user.is_expert_comment_enabled"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="createUserDialogEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllDepartments } from "@/mixins/GetAllDepartments.js";
import { GetAllRoles } from "@/mixins/GetAllRoles.js";
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import CountryList from "@/JsonFiles/CountryList.json";
export default {
  mixins: [GetAllDepartments, GetAllRoles, GetAllUsers],
  props: {
    dialogCreateBMSUser: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    user_type: "",
    userTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "User", value: "NORMAL_USER" },
    ],
    loading: false,
    create_user: {
      user_name: "",
      user_email_id: "",
      user_country_code: "+91",
      user_mobile_number: "",
      user_type: "",
      role_id: "",
      department_id: "",
      reporting_manager_id: "",
      is_expert_comment_enabled: false,
    },
    SnackBarComponent: {},
    CountryList: [],
  }),
  watch: {
    dialogCreateBMSUser(value) {
      if (value == true) {
        this.create_user.user_country_code = "+91";
        this.getAllDepartmentListMethod();
        this.getAllRoleListMethod();
        this.GetAllUsersListMethod();
        if (this.StoreObj.action == "EDIT") {
          this.create_user = {
            user_name: this.StoreObj.user_name,
            user_email_id: this.StoreObj.user_email_id,
            user_country_code: this.StoreObj.user_country_code,
            user_mobile_number: this.StoreObj.user_mobile_number,
            user_type: this.StoreObj.user_type,
            role_id: this.StoreObj.role_id,
            department_id: this.StoreObj.department_id,
            reporting_manager_id: this.StoreObj.reporting_manager_id,
            is_expert_comment_enabled: this.StoreObj.is_expert_comment_enabled ? this.StoreObj.is_expert_comment_enabled : false,
          };
        }
      }
    },
  },
  mounted() {
    this.CountryList = CountryList;
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.allCommonMethod(this.StoreObj.action);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async allCommonMethod(action) {
      switch (action) {
        case "CREATE":
          var mutationname = "createuser";
          var inputParams = {
            user_name: this.create_user.user_name,
            user_email_id: this.create_user.user_email_id,
            user_country_code: this.create_user.user_country_code,
            user_mobile_number: this.create_user.user_mobile_number,
            user_type: this.create_user.user_type,
            role_id: this.create_user.role_id,
            department_id: this.create_user.department_id,
            reporting_manager_id: this.create_user.reporting_manager_id,
            organization_id: this.$store.getters.get_current_user_details.organization_id,
            creator_email_id: this.$store.getters.get_user_email,
            is_expert_comment_enabled: this.create_user.is_expert_comment_enabled,
          };
          break;
        case "EDIT":
          mutationname = "updateuser";
          inputParams = {
            user_name: this.create_user.user_name,
            user_email_id: this.create_user.user_email_id,
            user_country_code: this.create_user.user_country_code,
            user_mobile_number: this.create_user.user_mobile_number,
            user_type: this.create_user.user_type,
            role_id: this.create_user.role_id,
            reporting_manager_id: this.create_user.reporting_manager_id,
            department_id: this.create_user.department_id,
            organization_id: this.$store.getters.get_current_user_details.organization_id,
            user_id: this.StoreObj.user_id,
            updater_email_id: this.$store.getters.get_user_email,
            is_expert_comment_enabled: this.create_user.is_expert_comment_enabled,
          };
          break;
      }
      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.createUserDialogEmit((this.Toggle = 2));
            this.loading = false;
          } else {
            this.loading = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    createUserDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
