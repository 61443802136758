<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateUserDialog
      :dialogCreateBMSUser="dialogCreateBMSUser"
      :StoreObj="StoreObj"
      @clicked="createBMSUsersDialogEmit"
    />
    <ActivateInactivateUserDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateUser="dialogActivateInactivateUser"
      @clicked="dialogActivateInactivateUserEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Users</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              @click="(dialogCreateBMSUser = true), (StoreObj.action = 'CREATE')"
              class="borderRadius"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :headers="userHeaders" :items="GetAllUsersList">
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllUsersList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <div class="FontSize">
              {{ item.user_name }}
            </div>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <div class="FontSize">
              {{ item.user_email_id }}
            </div>
          </template>
          <template v-slot:[`item.user_created_on`]="{ item }">
            <div class="FontSize">
              {{ item.user_created_on ? new Date(item.user_created_on).toLocaleString("en-In") : "-" }}
            </div>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <div class="FontSize">
              {{ item.user_type == "NORMAL_USER" ? "USER" : "ADMIN" }}
            </div>
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <div class="FontSize" :class="item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
              {{ item.user_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click="checkItem(item, 'EDIT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.user_email_id}`"></span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.user_id != $store.getters.get_current_user_details.user_id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click="checkItem(item, 'ACTION')"
                  ><v-icon small v-text="'mdi-delete'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Delete ${item.user_email_id}`"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateUserDialog from "@/components/Settings/UserManagement/Dialogs/CreateUserDialog.vue";
import ActivateInactivateUserDialog from "@/components/Settings/UserManagement/Dialogs/ActivateInactivateUserDialog.vue";
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
export default {
  components: {
    CreateUserDialog,
    ActivateInactivateUserDialog,
    Overlay,
  },
  mixins: [GetAllUsers],
  data: () => ({
    overlay: false,
    dialogCreateBMSUser: false,
    dialogActivateInactivateUser: false,
    GetAllUsersList: [],
    userHeaders: [
      {
        text: "Name",
        value: "user_name",
      },
      {
        text: "Email ID",
        value: "user_email_id",
      },
      {
        text: "Created On",
        value: "user_created_on",
      },
      {
        text: "User Type",
        value: "user_type",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    StoreObj: {},
    dialogDeleteUser: false,
  }),
  mounted() {
    this.GetAllUsersListMethod();
  },
  methods: {
    dialogDeleteUserEmit(Toggle) {
      this.dialogDeleteUser = false;
      if (Toggle == 2) {
        this.GetAllUsersListMethod();
      }
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.dialogActivateInactivateUser = false;
      if (Toggle == 2) {
        this.GetAllUsersListMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      if (action == "DELETE") {
        this.dialogDeleteUser = true;
      } else if (action == "ACTION") {
        this.dialogActivateInactivateUser = true;
      } else if (action == "EDIT") {
        this.StoreObj.action = "EDIT";
        this.dialogCreateBMSUser = true;
      }
    },
    createBMSUsersDialogEmit(Toggle) {
      this.dialogCreateBMSUser = false;
      if (Toggle == 2) {
        this.GetAllUsersListMethod();
      }
    },
  },
};
</script>
<style></style>
